import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthService } from './core';

@Component({
    selector: 'app-redirect',
    template: '',
})
export class RedirectComponent implements OnInit {
    constructor(
        private _authService: AuthService,
        private _router: Router
    ) {}

    public ngOnInit(): void {
        this._authService
            .getAuthenticationToken()
            .pipe(filter(Boolean))
            .subscribe(() => this._router.navigate(['/']));
    }
}
