import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';

const routes: Route[] = [
    {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full',
    },
    {
        path: 'home',
        loadChildren: () => import('./+home/home.module').then(m => m.HomeModule),
    },
    {
        loadChildren: () => import('./+license/license.module').then(m => m.LicenseModule),
        path: 'licenses',
    },
    {
        loadChildren: () => import('./+company/company.module').then(m => m.CompanyModule),
        path: 'companies',
    },
    {
        loadChildren: () => import('./+user/user.module').then(m => m.UserModule),
        path: 'user-management',
    },
    {
        loadChildren: () => import('./+updates/updates.module').then(m => m.UpdatesModule),
        path: 'updates',
    },
    {
        loadChildren: () => import('./+dashboard/dashboard.module').then(m => m.DashboardModule),
        path: 'dashboard',
    },
    {
        loadChildren: () => import('./+reports/reports.module').then(m => m.ReportsModule),
        path: 'reports',
    },
    {
        loadChildren: () => import('./+profile/profile.module').then(m => m.ProfileModule),
        path: 'profile',
    },
    {
        loadChildren: () => import('./+dealers/dealers.module').then(m => m.DealersModule),
        path: 'dealers',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
