import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { from, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core';
import { LayoutModule, MaterialModule } from './layout';
import { RedirectComponent } from './redirect.component';

export class LazyTranslateLoader implements TranslateLoader {
    public getTranslation(lang: string): Observable<unknown> {
        return from(import(`../assets/i18n/${lang}.json`)).pipe(catchError(() => of({})));
    }
}

@NgModule({
    bootstrap: [AppComponent],
    declarations: [AppComponent, RedirectComponent],
    imports: [BrowserModule,
        CommonModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        CoreModule.forRoot(),
        LayoutModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: LazyTranslateLoader,
            },
        }),
        MaterialModule.forRoot()],
    providers: [provideEnvironmentNgxMask(), provideHttpClient(withInterceptorsFromDi())],
})
export class AppModule { }
