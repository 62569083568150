import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-root',
    styleUrls: ['./app.component.scss'],
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
    constructor(private _translate: TranslateService) {}

    public ngOnInit(): void {
        this._translate.setDefaultLang('en');
        this._translate.use('en');
    }
}
